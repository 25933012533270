/**
 * Materialize is our main css framework.
 */

import M from '@materializecss/materialize';
window.M = M;

const initCollapsibles = () => {
  const sidebar = document.querySelectorAll('#nav-sidebar');
  const mobileMenuCollapsibles = document.querySelectorAll('#nav-mobile, #nav-mobile .collapsible');
  M.Collapsible.init(sidebar, {'accordion': false});
  M.Collapsible.init(mobileMenuCollapsibles, {'accordion': true});
};

const highlightSidebar = () => {
  // Set active state on menu element
  const full_url = window.location.href;
  if (window.location.pathname === '/') {
    // Do not highlight on home page
    return;
  }
  const $navLinks: NodeListOf<HTMLAnchorElement>
    = document.querySelectorAll('#nav-sidebar a.nav-link, #nav-mobile a.nav-link');

  // First look for an exact match including the search string
  let $currentPageLink = Array.from($navLinks).filter((node) => node.href === full_url);

  // If not found, look for the link that starts with the url
  if ($currentPageLink.length === 0) {
    $currentPageLink = Array.from($navLinks).filter((node) => (
      node.href.startsWith(full_url) || full_url.startsWith(node.href)
    ));
  }

  // Finally set links to active
  $currentPageLink.forEach(
    (node) => {
      // Set nav-link to active
      node.classList.add('active');
      // If link has a parent, mark it as active
      const li = node.closest('li.nav-item.top');
      const header = node.closest('div.collapsible-header');
      if (li !== null) {
        li.classList.add('active');
      }
      if (header !== null) {
        header.classList.add('active');
      }
    },
  );

  //re-init collapsibles to toggle active collapsible
  initCollapsibles();
};

const initMaterialize = () => {
  const mobileMenu = document.querySelectorAll('#nav-mobile');
  const dropdowns = document.querySelectorAll('.topmenu .dropdown-trigger');

  const menuOptions = {};
  const dropdownOptions = {
    'hover': true,
    'coverTrigger': false,
    'constrainWidth': false,
  };

  // init sidenav button and collapsing of mobile menu
  M.Sidenav.init(mobileMenu, menuOptions);
  // init dropdowns for menu
  M.Dropdown.init(dropdowns, dropdownOptions);

  // init collapsibles in sidebar and mobile menu
  initCollapsibles();
  highlightSidebar();
};

// Immediately call initMaterialize
initMaterialize();

/*
 * Do an additional update of Materialize fields when the page is loaded,
 * to ensure labels are rendered correctly (Vue might be filling values during load).
 */
window.addEventListener('load', M.updateTextFields);
